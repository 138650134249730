.modal-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 20;
}
.modal-top-bar {
  display: flex;
  justify-content: space-between;
}
.modal-top-bar-button {
  padding: 16px 24px;
}
.modal-container {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 80%;
  padding: 20px 40px;
  overflow-y: scroll;
}
.modal-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.close-icon {
  align-self: center;
  cursor: pointer;
  display: flex;
  height: 28px;
  margin-right: -20px;
  width: 28px;
}
.modal-tab-button {
  background-color: #fff;
  border: none;
  cursor: pointer;
  margin: 0px;
  padding: 8px 12px;
}
.modal-row {
  justify-content: space-between;
  display: flex;
}
.modal-row-container {
  background-color: #e8e8e8;
  border-radius: 16px;
  padding: 0px 24px;
  margin-top: 24px;
}
.modal-tab-button-active {
  background-color: #fff;
  border-color: #2e3646;
  border-style: solid;
  border-width: 0px 0px 3px;
  cursor: pointer;
  margin: 0px;
  padding: 8px 12px;
}
.modal-content-row {
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  width: 100%;
}
.modal-item-text {
  color: #2e3646;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  margin: 4px 0px;
}
.modal-text {
  color: #2e3646;
  cursor: default;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin: 4px 0px;
}
.owner-content {
  background-color: #e8e8e8;
  border-top: 0.5px solid rgba(0, 0, 0, 0.75);
  margin: 6px 0px;
  padding: 20px;
  width: 93%;
}
.owner-content-row {
  display: flex;
  justify-content: space-between;
  padding: 6px 0px;
  width: 100%;
}
.owner-content-item-text {
  color: #2e3646;
  cursor: default;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  margin: 0px;
}
.owner-content-text {
  color: #2e3646;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  text-transform: uppercase;
}
.accordion-content {
  background-color: #e8e8e8;
  border: 0.5px solid rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  margin: 10px 0px;
  padding: 5px 20px;
  width: 100%;
}
.accordion-content-row {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 0px;
  width: 100%;
}
.accordion-header-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  column-gap: 8px;
  width: 100%;
}
.accordion-content-container {
  margin: 12px 0px 0px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.75);
  padding: 8px 0px 0px;
  width: 100%;
}
.table-container {
  width: 100%;
}
.table-heading-text {
  color: #2e3646;
  cursor: default;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  margin: 4px;
}
.table-body-data {
  text-align: center;
}
.table-data-text {
  color: #2e3646;
  cursor: default;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  margin: 4px;
}
