.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(6, 8, 12, 0.7);
}
.modal-overlay,
.header-modal-custom,
.info-modal-global,
.modal-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content-global {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 400px;
  padding: 60px;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
}
.header-modal-custom {
  gap: 5px;
  color: #2e3646;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: -0.02em;
  font-size: 24px;
  text-align: center;
}
.info-modal-global {
  flex-direction: column;
  padding: 24px;
  text-align: center;
  gap: 48px;
}
.modal-close-btn {
  padding: 12px;
  border: none;
  background-color: transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, 50%);
}
.modal-close-btn:hover {
  background-color: #f7aa47;
}
.loading-spinner {
  width: 60px;
  height: 60px;
  border: 4px solid #f7aa47;
  border-radius: 50%;
  border-top-color: #0001;
  display: inline-block;
  animation: loadingspinner 0.8s linear infinite;
}
@keyframes loadingspinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
