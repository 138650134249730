.checkbox-label {
  align-items: center;
  color: rgba(44, 44, 44, 0.78);
  column-gap: 8px;
  cursor: pointer;
  display: flex;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  width: fit-content;
}
.checkbox {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 1px solid rgba(210, 210, 210, 1);
  cursor: pointer;
  height: 20px;
  margin: 0px;
  padding: 2px;
  transition: all 200ms ease-in-out;
  width: 20px;
}
.checkbox-active {
  border-color: #f7aa47;
  background-color: #f7aa47;
  transition: all 200ms ease-in-out;
}
.checkmark-icon {
  height: 20px;
  position: absolute;
}
.dot-mark {
  border: solid 0.5px rgba(0, 0, 0, 0.3);
  border-radius: 64px;
  height: 10px;
  margin-top: 1.5px;
  width: 10px;
}
