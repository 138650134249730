.map-container {
  inset: 0px;
  position: fixed;
  z-index: 1;
}
.leaflet-tooltip.parcels-tooltip {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: orange;
  display: var(--dynamic-display-text);
  font-size: 20px;
  font-weight: 600;
}
.leaflet-tooltip.parcels-tooltip::before {
  border: none;
}
.leaflet-tooltip.buildings-tooltip {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  padding: 0px;
  display: var(--dynamic-display-text);
  font-size: 20px;
  font-weight: 600;
}
.leaflet-tooltip.buildings-tooltip::before {
  border: none;
}
.leaflet-tooltip.parcels-tooltip,
.leaflet-tooltip.buildings-tooltip {
  font-size: 14px;
  margin: 0px !important;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.leaflet-popup-content-wrapper {
  border-radius: 6px;
}
.popup {
  border-radius: 6px;
  padding: 0;
}
.marker,
.marker-kucni {
  background-color: transparent;
  color: #fff;
  display: var(--dynamic-display-text);
  font-size: 16px;
  margin: 0px !important;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.marker-kucni {
  font-weight: 600;
  color: yellow;
}
.popup-button {
  background-color: #2e3646;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  font-family: Inter;
}
.popup-button:hover {
  background-color: #4f5c78;
  transition: background-color 200ms ease-in-out;
}
.popup-button p {
  color: #f8f9fb;
  margin: 10px 42px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.001em;
  text-transform: uppercase;
}
.hide {
  display: none;
}

.btn-loading-spinner {
  width: 30px;
  height: 30px;
  border: 4px solid #2e3646;
  border-radius: 50%;
  border-top-color: #0001;
  display: inline-block;
  margin-left: 8px;
  animation: loadingspinner 0.8s linear infinite;
}
@keyframes loadingspinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 320px) and (max-width: 1440px) {
  .leaflet-right {
    top: 12%;
  }
}
