.buttons-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  row-gap: 8px;
  top: 45%;
  z-index: 10;
}
.button-box {
  align-items: center;
  background-color: #2e3646;
  border: none;
  border-radius: 10px;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  padding: 10px 8px;
  transition: background-color 200ms ease-in-out;
  width: 64px;
}
.button-box:hover {
  background-color: #4f5c78;
  transition: background-color 200ms ease-in-out;
}
.focus {
  background-color: #4f5c78;
}
.button-text {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.001em;
  margin: 0px;
  text-transform: capitalize;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .buttons-container {
    right: 10%;
  }
}
@media (min-width: 1024px) {
  .buttons-container {
    right: 12px;
  }
}
