.header-menu-container {
  background-color: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  left: 3%;
  padding: 24px;
  height: 70%;
  position: absolute;
  top: 102px;
  width: 467px;
  z-index: 20;
}
.menu-close-row {
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: flex-end;
  width: 100%;
}
.menu-close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 32px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 22px;
}
.menu-list {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}
.menu-list-section {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.menu-text-bold {
  color: #2e3646;
  cursor: default;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
}
.menu-text {
  color: rgba(44, 44, 44, 0.78);
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  transition: color 200ms ease-in-out;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.search-input-container {
  display: flex;
}
.search-button {
  align-items: center;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 0px 8px;
}
.search-button-text {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  text-transform: capitalize;
}
.bar-loader-container {
  align-items: center;
  display: flex;
  height: 54px;
  padding-left: 28px;
}

.label {
  align-items: center;
  color: rgba(44, 44, 44, 0.78);
  column-gap: 8px;
  cursor: pointer;
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  width: fit-content;
}
.pp-container {
  padding-left: 27px;
}
